@font-face {
  font-family: 'coda';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./static/font/Coda-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span,
div,
input,
textarea,
button,
select,
a,
pre {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'coda', sans-serif !important;
}

@layer utilities {
  .bg-border {
    background-image: linear-gradient(to bottom, #a522ec, #c92d27);
    background-size: 200% 200%;
    animation: borderAnimation 0.5s ease-in-out infinite;
  }

  @keyframes borderAnimation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
      background-image: linear-gradient(to bottom, #c92d27, #a522ec); /* ترتیب معکوس رنگ‌ها */
    }
    100% {
      background-position: 0% 0%;
    }
  }


.bg-btn-gradient {
    background: linear-gradient(175.53deg, #BA56F0 42.14%, #E1463A 101.71%);
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.bg-btn-gradient-wave {
  content:"";
  position:absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  z-index:-1;
  border: 1px solid #BA56F0;
  border-radius:50px;
  animation:ripple-wave 2s linear infinite;
  transform:scale(1);
  transition:all .5s ease-in-out
}
@keyframes ripple-wave {
  0% {
    opacity:.8;
    transform:scale(.9)
  }
  to {
    opacity:0;
    transform:scale(2)
  }
}

.bg-btn-gradient-wave:nth-child(2) {
  animation-delay: 0s;
}

.bg-btn-gradient-wave:nth-child(3) {
  animation-delay: 0.6s;
}

.bg-btn-gradient-wave:nth-child(4) {
  animation-delay: 1.2s;
}